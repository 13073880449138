@import "../../../scss/variable.scss";

.view-button {
  text-align: center;
  font-family: $poppins !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  color: $white !important;
  background-color: $secondary_color !important;
  text-transform: capitalize !important;

  img {
    width: 15px;
    height: 15px;
    margin-right: 4px;
  }
}

.delete-view-button {
  background-color: $errorClr !important;
  text-transform: capitalize !important;
}
