.action-btn {
  padding: 4px !important;
  border-radius: 50%;
  background-color: transparent !important;

  img {
    width: 20px;
    height: 20px;
  }
}
