@mixin table-view {
  display: flex;
  flex-direction: column;
  > div {
    display: flex;
    align-items: baseline;
    margin: 0.5em;
    > * {
      width: 50%;
    }
    span {
      font-size: 1em;
    }
  }
}

@mixin default-input-style {
  position: relative;
  font: inherit;
  color: currentColor;
  width: 100%;
  border: 0;
  height: 1.1876em;
  margin: 0;
  display: block;
  padding: 6px 0 7px;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  animation-name: mui-auto-fill-cancel;
  letter-spacing: inherit;
  animation-duration: 10ms;
  -webkit-tap-highlight-color: transparent;
}

@mixin input-label-style {
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
}

@mixin items-center-aligned {
  display: flex;
  align-items: baseline;
}
