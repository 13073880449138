@import "../../../scss/variable.scss";

.custom-auto-padding .MuiInputLabel-outlined {
  transform: translate(0px, 20px) !important;
}
.custom-auto-padding .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(0px, -5px) scale(0.75) !important;
}

.custom-auto-padding
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
  .MuiAutocomplete-input:first-child {
  padding-left: 0 !important;
}
.custom-auto-padding
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding-left: 0 !important;
}
.width-90 {
  width: 90% !important;
}
.width-95 {
  width: 95% !important;
}
.overflow-hidden {
  overflow: hidden !important;
}

.input-color-picker {
  width: 95% !important;
  height: 36px !important;
}
