@import "./variable.scss";
@import "./mixin.scss";

body {
  background: $background;
}

.unauth-content {
  font-family: $poppins;
}

.login-card {
  position: absolute;
  width: 60vw;
  height: 70vh;
  background-color: $white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px !important;
}

.login-card .left-section {
  width: 60%;
  background: $primary_color;
  position: relative;
  height: 100%;
  float: left;

  .bottom-bar {
    position: absolute;
    bottom: 0;
    z-index: 8;
  }
}

.login-card .right-section {
  width: 40%;
  float: left;
  height: 100%;
  position: relative;
}

.login-card .right-section .form-sec {
  position: absolute;
  top: 30%;
  width: 96%;
  left: 2%;
  transform: translate(0%, -30%);

  .card-title {
    font-size: 30px;
    text-align: center;
    font-weight: $fnt600;
    color: $primary_color;
  }
}

.login-btn-parent {
  width: 100%;
  display: block;

  .login-submit-btn {
    width: 100%;
    padding: 16px !important;
    text-align: center;
    margin-top: 36px !important;
    color: $primary_color;
    border-radius: 50px !important;
    background: red;

    .MuiButton-label {
      font-size: 16px !important;
      font-family: $poppins;
    }
  }
}

.login-input {
  margin-top: 40px !important;
}

.forgot-password-text {
  text-align: right;
  font-size: 14px;
  color: $secondary_color;
  margin-top: 10px;

  span {
    cursor: pointer;
  }
}

.left-section-content {
  z-index: 9999;
  padding: 20px 35px;

  h1 {
    color: $white;
    font-size: 40px;
    font-weight: $fnt400;
    margin-bottom: 0;
  }

  p {
    margin-top: 0;
    color: $white;
    font-size: 16px;
    letter-spacing: 1px;
  }

  ul {
    margin: 60px 0 0;
    padding: 0;

    li {
      list-style: none;
      color: $background;
      font-weight: $fnt400;
      font-size: 14px;
      letter-spacing: 1px;
      color: $background;
      position: relative;
      left: 25px;
      margin-top: 25px;

      img {
        width: 15px;
        margin-right: 5px;
        position: absolute;
        left: -25px;
        top: 5px;
      }
    }
  }
}

.MuiFormLabel-asterisk {
  color: $errorClr;
}

.mobile-field input::-webkit-outer-spin-button,
.mobile-field input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.mobile-field input[type="number"] {
  -moz-appearance: textfield;
}

.submit-button {
  width: 100% !important;
  background: $primary_color !important;
  color: $white !important;
  margin-top: 10% !important;
  padding: 10px 0px !important;
  border-radius: 50px !important;
  font-family: $poppins !important;
  font-size: 16px !important;
  font-weight: $fnt400 !important;
}

.eye-icon {
  cursor: pointer !important;
  width: 15px;
}

@media screen and (max-width: 1366px) {
  .left-section-content {
    padding: 30px 40px;

    h1 {
      font-size: 35px;
    }
  }
  .text-ellipsis {
    max-width: 170px !important;
  }
}

@media screen and (max-width: 1200px) {
  .left-section-content {
    padding: 30px 30px;

    h1 {
      font-size: 25px;
    }

    ul {
      li {
        font-size: 13px;
      }
    }
  }
}

.error-msg {
  color: $errorClr;
  font-size: 10px;
}

.circular-loader {
  width: 60px !important;
  height: 60px !important;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -30px;
  margin-left: -30px;
  z-index: 9999;
}

.circular-loader-color {
  color: $secondary_color !important;
}

.side-navbar-container {
  max-width: 275px !important;
  flex-basis: 275px !important;
}

.component-container {
  max-width: calc(100% - 275px) !important;
  flex-basis: calc(100% - 275px) !important;
  position: relative;

  .content {
    position: absolute;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: inherit;
    width: 100%;
    height: calc(100vh - 94px);
    top: 93px;
    padding: 18px 36px;
  }
}

.view-box {
  width: 100%;
  height: auto;
  font-size: 18px;
  font-family: $poppins;
  font-weight: normal;
  padding-top: 0px !important;
  padding-bottom: 0px !important;

  li {
    padding-bottom: 16px;
    padding-top: 16px;
    font-weight: 500;

    label {
      width: 40%;
      display: inline-block;
      color: $labelGrey;
      margin-bottom: 0px !important;
      font-size: 16px;
    }

    span {
      font-size: 16px;
      width: 60%;
      display: inline-block;
      color: $primary_color;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      p {
        margin: 0px;
      }
    }

    span.name {
      color: $secondary_color;
      text-transform: capitalize;
    }

    span.nameLink {
      color: $commonLinkClr;
      cursor: pointer;
    }
  }
}

.react-tabs__tab--selected {
  color: $successClr !important;
  border: none !important;
  border-bottom: 2px solid $successClr !important;
}

.react-tabs__tab {
  margin-top: 2em;
}

.table-view {
  @include table-view;
}

.block-separator-heading {
  background: $lightestGrey;
  padding: 1em;
  margin: 1em 0;
  margin-right: 1em;
  border-radius: 0.3em;
  font-family: $poppins;
}

.cursor-pointer {
  cursor: pointer;
}

.input-form-heading {
  @include default-input-style;

  label,
  span {
    @include input-label-style;
  }

  p {
    padding-bottom: 2px;
    border-bottom: 1px solid;
    margin-bottom: 0;
  }
}

.MuiAutocomplete-root {
  max-height: 150px;
  overflow-y: scroll;

  .MuiOutlinedInput-notchedOutline {
    border: none !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.23) !important;
  }
}

.active-status {
  color: $successClr !important;
}

.inactive-status {
  color: $errorClr !important;
}

//Default icons dimension.
i {
  width: 1rem;
  height: 1rem;
}

//Inherits i tag dimensions.
svg {
  width: inherit;
  height: inherit;
}

.start-with-capital {
  text-transform: capitalize;
}

.MuiOutlinedInput-root {
  border-radius: 0 !important;
}

.licence-view .table-view > div {
  margin: 0.3em;
}

.vendify-logo img {
  width: 220px;
  position: fixed;
  left: 35px;
  top: 20px;
}

.form-input--checkbox {
  display: flex;
  align-items: flex-end;
  margin-bottom: 16px;
  span {
    padding-left: 0;
  }
}

@for $value from 1 through 100 {
  .width-#{$value} {
    width: #{$value + "%"} !important;
  }
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  position: relative;
}

.dash-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $white;
  height: 50px;
  margin-bottom: 20px;
  border-radius: 10px;
  font-family: $poppins;

  .left-header,
  .right-header {
    ul {
      margin: 0;
      padding: 0;
    }

    li {
      display: inline-block;
      list-style: none;
      margin: 0px 10px;
      font-size: 14px;
      font-weight: $fnt500;
    }
  }

  .left-header ul li {
    background: $secondary_grey;
    padding: 3px 10px;
    border-radius: 20px;
    text-transform: capitalize;
  }

  .left-header ul li.backslash {
    background: transparent;
    padding: 3px 0px;
    border-radius: 0px;
    margin: 0px;
    font-size: 18px;
  }

  .left-header ul li.active-breadcrumb {
    color: $commonLinkClr;
    cursor: pointer;
  }

  .left-header ul li img,
  .right-header ul li img {
    width: 16px;
    transform: translateY(-2px);
  }
}

.MuiDrawer-root .MuiDrawer-docked div {
  position: absolute;
  width: 250px !important;
}
// it is use to prevent from click when we don't want to redirect after clicking
.pointer-none {
  pointer-events: none;
  color: #000;
}

.text-ellipses {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
  white-space: nowrap;
}

.react-tabs__tab-list {
  width: 95%;
}
