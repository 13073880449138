@import "../../../scss/variable.scss";

.add-user-modal {
  width: 737px;
  height: auto;
  padding: 0px;
  font-family: $poppins;
  font-size: 18px;
  font-weight: $fnt500;
  padding-bottom: 20px;

  .dialog-title {
    width: 100%;
    background-color: $primary_color;

    h2 {
      color: $white;
      font-size: 28px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-family: $poppins;
      font-weight: $fnt400 !important;

      span {
        flex-grow: 1;
        display: inline-block;
      }

      img {
        width: 16px;
        height: 16px;
        transform: translateY(14px);
        cursor: pointer;
      }
    }
  }

  .dialog-content {
    padding: 16px 24px;
  }
}

.save-btn {
  font-size: 16px !important;
  font-weight: $fnt400 !important;
  font-family: $poppins !important;
  background-color: $secondary_color !important;
  color: $white !important;
  text-align: center;
  margin-top: 16px !important;
  margin-right: 24px !important;
  text-transform: capitalize !important;
  width: auto;
  height: 44px;

  img {
    width: 14px;
    height: 14px;
    margin-right: 4px;
  }
}

.cancel-btn {
  background-color: $disabled_grey !important;
  text-transform: capitalize !important;
  font-size: 16px;
}

@mixin statusCircle {
  width: 10px;
  display: inline-block;
  height: 10px;
  margin-right: 10px;
  border-radius: 50%;
}

.enabled-circle {
  @include statusCircle();
  background: $successClr;
}

.disabled-circle {
  @include statusCircle();
  background: $disabled_grey;
}
