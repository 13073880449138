@import "../../../scss/variable.scss";

.delete-modal {
  width: 601px;
  height: 407px;
}

.delete-popup-icon {
  width: 100px;
  height: 100px;
  margin-top: 52px;
}

.delete-dialog-content {
  font-size: 18px;
  font-weight: normal;
  font-family: $poppins;
  color: #707070;

  h3 {
    font-size: 32px;
    color: $primary_color;
  }

  label {
    color: $secondary_color;
    text-transform: capitalize;
  }
}

.cancel-btn {
  width: auto;
  height: 44px;
  background: $disabled_grey 0% 0% no-repeat padding-box !important;
  border-radius: 5px !important;
  color: $white !important;
  font-size: 16px;
  font-weight: $fnt400 !important;
  font-family: $poppins !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  text-transform: capitalize !important;
}

.delete-btn {
  background-color: $errorClr !important;
  color: $white !important;
  text-transform: capitalize !important;
  font-size: 15px;
  font-weight: $fnt400 !important;
  font-family: $poppins !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  width: auto;
  height: 44px;
}

.dont-log-out {
  background-color: $secondary_color !important;
}

.got-it-btn {
  background-color: $successClr !important;
}
