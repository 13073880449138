@import url("https://fonts.googleapis.com/css?family=Montserrat:300i,400,400i,500,500i,600,600i,700,700i,800&display=swap");

$poppins: "Poppins", sans-serif;
$secondary_grey: #ecf0f3;
$primary_color: #0b2239;
$secondary_color: #4ad295;
$background: #efefef;
$white: #ffffff;
$black: #333333;
$title_black: #2a2a2a;
$disabled_grey: #a1a1a1;
$errorClr: #ff0018;
$successClr: #4cb04e;
$warningClr: #f9c83f;
$commonLinkClr: #3987fd;
$lightGrey: #dadada;
$lightestGrey: #f1f1f1;
$labelGrey: #707070;
$lightBlue: #097bdd;
$darkishRed: #ff2e43;
$overlay: rgba(42, 42, 42, 0.5);
$fnt400: 400;
$fnt500: 500;
$fnt600: 600;
$fnt700: 700;
