.licenses-list {
  .MuiCard-root {
    overflow-x: auto !important;
  }

  .status-active {
    color: $successClr;
  }
  .status-pending {
    color: $errorClr;
  }
  .action-btn.license-btn.eye {
    img {
      background: $lightBlue;
    }
  }

  .action-btn.license-btn.edit {
    img {
      background: $warningClr;
    }
  }

  .action-btn.license-btn {
    img {
      height: 25px;
      width: 25px;
      padding: 5px;
      border-radius: 2px;
    }
  }

  .approve-button {
    font-size: 0.6em;
    border: none;
    padding: 5px 10px;
    border-radius: 3px;
    color: white;
    &.enabled-btn {
      background: $successClr;
    }

    &.disabled-btn {
      background: $lightGrey;
      pointer-events: none;
      cursor: not-allowed;
    }
  }
}
