$primaryColor: #f95a3f;
$secondaryColor: #666f78;
$white: #fff;
$lineHeight: 60px;
$blockWidth: 100%;
$size: 20px;

@mixin flexProperty($new) {
  display: flex;
  justify-content: $new;
  align-items: center;
}

.device-preview {
  border-radius: 5px;
  border: 1px solid #707070;
  border-top: none;
  margin-top: 15px;
  margin-bottom: 15px;

  .device-preview__header {
    background: $primaryColor;
    width: $blockWidth;
    height: auto;
    border-radius: 5px 5px 0px 0px;

    h1 {
      color: $white;
      font-size: 16px;
      margin-top: 15px;
      max-width: 320px !important;
    }
    .device-preview__header-logo-img {
      width: 50px;
      height: auto;
      margin: 5px 0px;
    }
  }

  .device-preview__subheader {
    width: $blockWidth;
    height: 35px;
    background: #212121;
    color: $white;
    line-height: 35px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
  }

  .device-preview__cart-icon {
    float: right;
    line-height: $lineHeight;
    color: $white;
    font-size: 20px;
    position: relative;

    i {
      width: 24px;
      height: 24px;
    }

    span {
      content: "";
      position: relative;
      top: -16px;
      right: 5px;
      font-size: 10px;
      height: $size;
      background: $secondaryColor;
      border-radius: 50%;
      padding: 2px 4px;
      line-height: $size;
    }
  }

  .device-preview__content ul {
    @include flexProperty(space-between);
    margin: 0px 0px 20px;
    padding: 0px;

    li {
      list-style: none;
      font-weight: 600;
      color: $primaryColor;
      width: 80px;
      border-bottom: 3px solid $primaryColor;
    }
  }

  .device-preview__product-box {
    width: 96%;
    border: 3px solid #c9c9c9;
    border-radius: 5px;
    padding: 5px;
    position: relative;

    h2 {
      font-size: 14px;
      font-weight: 600;
      color: $primaryColor;
      margin: 10px 0px 0px;
    }

    p {
      font-size: 12px;
      color: #767676;
      margin-bottom: 0px;
      font-weight: 500;
    }

    .device-preview__featured-product {
      position: absolute;
      right: -1px;
      background: $primaryColor;
      padding: 2px 4px;
      top: -1px;
      border-radius: 0px 0px 0px 5px;

      img {
        width: 20px;
      }
    }
  }

  .featured {
    border: 3px solid $primaryColor;
  }

  .device-preview__product-box-footer {
    @include flexProperty(space-between);

    h2 {
      margin: 0px;
    }

    button {
      padding: 0px;
      pointer-events: none;
      color: #fff;
    }

    button span.plus-icon {
      background: $primaryColor;
      padding: 5px 8px;
      position: relative;
      right: -8.899px;
      border-radius: 0px 3px 3px 0px;
    }
  }

  .previewButtons {
    width: 96%;
    text-align: right;

    button {
      margin: 0px 0px 15px;
      color: $white;
    }

    button.customColor-review-btn {
      color: $primaryColor;
    }
  }

  .customColor {
    background: $primaryColor;
  }

  .customColor-review-btn {
    border: 1px solid $primaryColor;
    color: $primaryColor;
    background-color: $white;
    font-size: 14px;
  }

  .device-preview__product-box-button {
    font-size: 12px;
  }

  .device-preview__product-box-image {
    @include flexProperty(center);

    img {
      width: 50px;
    }
  }
}

@media only screen and (max-width: 1330px) {
  .device-preview__product-box {
    width: 95% !important;
  }
}
