.upload-image-container {
  width: 167px;
  height: 192px;
  background-color: #fafafa;
  border: 1px solid #707070;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  font-weight: normal;
  color: #222;
  cursor: grab;
  img {
    width: 100%;
  }
  img.upload-image-icon {
    width: 52px;
    height: 35px;
    margin-bottom: 20px;
  }
}
