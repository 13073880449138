.confirm-license-modal {
  max-width: unset !important;
  width: 50em;
  padding: 0px;
  font-family: $poppins;
  font-size: 14px;
  font-weight: $fnt500;

  .dialog-title {
    width: 100%;
    background-color: $primary_color;

    h2 {
      color: $white;
      font-size: 28px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: baseline;
      span {
        flex-grow: 1;
        display: inline-block;
      }

      img {
        width: 16px;
        height: 16px;
        cursor: pointer;
      }
    }
  }

  .dialog-content {
    padding: 30px;
    .highlighed-company-name {
      color: $successClr;
      text-transform: capitalize;
    }
  }

  button {
    background: transparent;
    border: none;
    padding: 10px 20px;
    background: $successClr;
    margin: 10px;
    color: white;
    border-radius: 5px;
    display: flex;
    align-items: baseline;
    font-size: 16px;
    img {
      display: inline-block;
      width: 15px;
      margin-right: 10px;
    }
  }
  .cancel-button {
    background: $labelGrey !important;
  }
}
