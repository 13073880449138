.left-section-content.reset-password {
  ul {
    margin-top: 50px !important;
  }
  li {
    position: relative;
    left: 25px;

    img {
      position: absolute;
      left: -25px;
      top: 5px;
    }
  }
  .reset-password-instruction {
    margin-top: 10px !important;
    li {
      list-style: disc;
      margin-top: 0;
      line-height: 32px;
      left: 30px;
      position: relative;
    }
  }
}

.reset-password_redirect-login {
  @include items-center-aligned;
  cursor: pointer;
  justify-content: flex-end;
  font-size: 0.9rem;
  color: $secondary_color;

  svg {
    fill: $secondary_color;
  }

  i {
    margin-right: 5px;
  }
}
