.view-license-modal {
  max-width: unset !important;
  width: 50em;
  padding: 0px;
  font-family: $poppins;
  font-size: 18px;
  font-weight: $fnt500;

  .company-license-services {
    height: 220px !important;
  }

  .dialog-title {
    width: 100%;
    background-color: $primary_color;

    h2 {
      color: $white;
      font-size: 28px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: baseline;

      span {
        flex-grow: 1;
        display: inline-block;
      }

      img {
        width: 16px;
        height: 16px;
        cursor: pointer;
      }
    }
  }

  .dialog-content {
    padding: 16px 24px;

    .table-view {
      > div {
        h6 {
          width: 25%;
        }

        > *:not(h6) {
          width: 75%;
          font-size: 0.9em;
          font-weight: 400;
        }
      }
    }
  }

  button {
    background: transparent;
    border: none;
    padding: 10px 20px;
    background: $successClr;
    margin: 10px;
    color: white;
    border-radius: 5px;
    display: flex;
    align-items: baseline;
    font-size: 16px;

    img {
      display: inline-block;
      width: 15px;
      margin-right: 10px;
    }
  }

  .cancel-button {
    background: $labelGrey !important;
  }

  .license-not-approved {
    background: $darkishRed;
    color: white;
    padding: 5px 12px;
    border-radius: 20px;
    font-size: 12px;
    margin-left: 10px;
  }
}

ul.company-license-services {
  margin: 0;
  border: 1px dashed #dadada;
  box-sizing: border-box;
  padding: 10px;
}

.company-license-services li {
  list-style: none;
  font-size: 16px;
}

.company-license-services li span {
  background: #efefef;
  padding: 5px 5px;
  border-radius: 5px;
  font-weight: $fnt600;
  display: block;
  margin: 5px 0px 5px 0px;
  font-size: 100% !important;
}
