@import "../../../scss/variable.scss";

.custom-table {
  width: 100%;
  margin-top: 20px;
  font-family: $poppins !important;
  transition: linear;
  transition-duration: 1s;

  .custom-table-head {
    background-color: #f1f1f1;
    border-radius: 5px;

    th {
      color: $primary_color;
      font-size: 16px;
      font-weight: 600;
      font-family: $poppins;
      position: relative;
    }
  }

  td {
    text-transform: capitalize;
    white-space: nowrap;
    font-family: $poppins;
    font-weight: $fnt500;
  }
}
.load-table ul {
  margin: 0;
  padding: 0;
}
.load-table ul li {
  display: inline-block;
  margin-right: 5px;
}
.load-table ul li:nth-child(2) {
  animation: firstMove 2s infinite;
}
.load-table ul li:nth-child(3) {
  animation: firstMove 2s infinite;
  animation-delay: 0.5s;
}
.load-table ul li:nth-child(4) {
  animation: firstMove 2s infinite;
  animation-delay: 1s;
}
@keyframes firstMove {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.MuiTablePagination-toolbar {
  width: 400px;
}
