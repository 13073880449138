@import "../../../scss/variable.scss";

.go-back-icon {
  width: 27px;
  height: 27px;
  margin-right: 15px;
  transform: translateY(-5px);
  cursor: pointer;
}

.container-title {
  color: $black;
  font-size: 25px;
  font-weight: $fnt500;
  font-family: $poppins;
}

.add-button {
  padding: 8px !important;
  background-color: $primary_color !important;
  color: $white !important;
  border-radius: 5px !important;
  font-family: $poppins !important;
  font-size: 16px !important;
  font-weight: $fnt400 !important;
  transform: translateY(-6px);
  text-transform: capitalize !important;

  img {
    width: 13px;
    height: 13px;
    margin-right: 4px;
  }
}

.container-card {
  width: 100%;
  // height: calc(100vh - 240px);
  overflow-x: hidden;
  overflow-y: auto;
  padding: 10px 15px;
  margin-top: 5px;
}
