.company-license-modal {
  max-width: unset !important;
  width: 50em;
  padding: 0px;
  font-family: $poppins;
  font-size: 18px;
  font-weight: $fnt500;

  .dialog-title {
    width: 100%;
    background-color: $primary_color;

    h2 {
      color: $white;
      font-size: 28px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: baseline;
      span {
        flex-grow: 1;
        display: inline-block;
      }

      img {
        width: 16px;
        height: 16px;
        transform: translateY(10px);
        cursor: pointer;
      }
    }
  }

  .dialog-content {
    padding: 16px 10px;
  }

  button {
    background: transparent;
    border: none;
  }

  .save-btn {
    width: auto;
    height: 44px;
    border-radius: 5px;
    text-transform: capitalize !important;
    font-size: 16px;
  }
}

.company-license-services {
  height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 0.8em;
  padding-left: 20px;
  line-height: 2;
}

.company-license.table-view {
  > div {
    h6 {
      width: 25%;
    }
  }
}
ul.company-license-services {
  width: 100%;
}
