@import "../../../scss/mixin.scss";
@import "../../../scss/variable.scss";

.view-company_details {
  @include table-view;
  font-family: $poppins;
  .view-license-button {
    background: $successClr;
    width: 250px;
    border: none;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
  }
}

.view-company_name {
  color: $successClr;
}

.view-company_name {
  color: $successClr;
}

.view-company__integration-admins {
  .integration-admins--admin {
    font-size: 15px;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
}

.view-company__support-admin {
  display: flex;
  font-size: 15px;
  flex-direction: column;
}
.custom-accordian {
  box-shadow: none !important;
}
.header-accordian {
  background: #f1f1f1 !important;
  border-radius: 4px !important;
  min-height: 44px !important;
  padding: 0px 10px !important;
  overflow: hidden !important;
  .MuiAccordionSummary-content {
    margin: 0 !important;
  }
}
.collapsible-navbar-title,
.collapsible-navbar-title h6 {
  margin-bottom: 0;
}
.header-accordian span img {
  width: 20px;
}
.custom-accordian .MuiAccordionSummary-expandIcon.Mui-expanded {
  transform: none !important;
  background-image: url("../../../assets/icons/minus.svg") !important;
  background-size: 20px !important;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: -2px;
  span img {
    display: none;
  }
}
.accordian-details {
  display: initial !important;
  p {
    display: grid;
    grid-template-columns: 30% 70%;
    margin-bottom: 10px;
    h6 {
      margin-right: 15px;
    }
  }
}
.cancel-cheque {
  width: 100px;
}

@media screen and (max-width: 1366px) {
  .text-ellipses {
    max-width: 200px;
  }
}
