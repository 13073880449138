@import "../../../scss/variable.scss";

.forgot-password_redirect-login {
  @include items-center-aligned;
  cursor: pointer;
  justify-content: flex-end;
  font-size: 0.9rem;
  color: $secondary_color;

  svg {
    fill: $secondary_color;
  }
  i {
    margin-right: 5px;
  }
}

.forgot-password .email-icon {
  margin-bottom: 0.2em;
}
