.side-navbar {
  width: 290px;
  height: calc(100% - 90px) !important;
  top: 88px !important;
  background-color: $primary_color !important;
  padding-top: 45px;
  overflow-x: hidden;

  .side-navbar-link {
    height: 49px;
    min-height: 30px !important;
    padding: 8px;
    font-size: 14px;
    font-family: $poppins;
    border-radius: 2px;
    margin-bottom: 18px;

    &:hover {
      cursor: pointer;
    }

    div.sidebar-anchor {
      width: 100%;
      display: flex;
      flex-direction: row;

      img {
        width: 16px;
        height: 16px;
        margin-left: 0px;
        transform: translateY(4px);
      }
    }

    label {
      transform: translateY(1px);
      &:hover {
        cursor: pointer;
      }
    }
  }

  .side-navbar-link.inactive-link {
    color: $white;
  }

  .side-navbar-link.sub-inactive-link {
    color: $white;
    height: auto;
    margin-bottom: 8px;
  }

  .side-navbar-link.active-link {
    color: $white;
    background: $secondary_color;
  }

  .side-navbar-link.sub-active-link {
    color: $secondary_color;
    height: auto;
    margin-bottom: 8px;
  }
}

.accordion-root-sidenavbar {
  background-color: transparent !important;
  box-shadow: none !important;
  position: static !important;
  margin-bottom: 0px !important;
}

.accordion-root-sidenavbar.Mui-expanded {
  margin: 0px !important;
  margin-bottom: 0px !important;
}

.accordion-expanded-sidenavbar-link {
  height: 30px;
  margin: 0px !important;
}
