.top-navbar {
  padding: 10px 22px;
  box-shadow: none !important;
  border-bottom: 0px !important;

  .vendify-logo {
    width: 230px;
    height: 68px;
  }

  .navbar-icon-btn {
    margin-top: 0.4em;
    margin-right: 0.2em;
    border-radius: 50px;
    border: 2px solid $disabled_grey;
    font-size: 16px;
    font-family: $poppins;
    padding-left: 25px;
    padding-right: 25px;

    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
    span {
      color: $primary_color;
    }
  }
}

.search-page-input > div {
  border-radius: 31px;
}
.dropdown-icon {
  text-align: right;
}
.profile-name {
  color: $secondary_color !important;
}
.profile-title {
  font-size: 16px;
  font-weight: $fnt500;
  color: $white;
  padding: 7px 20px 7px 20px;
  margin-bottom: 0;
  border-bottom: 1px solid #dadada;
  padding-bottom: 10px;
  background: $primary_color;
  font-family: $poppins;
}
.menu-item-profile {
  width: auto;
}
.profile-drawer .MuiList-padding {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.menu-item-profile li {
  padding: 7px 10px;
  img {
    width: 20px;
    margin-right: 3px;
  }
}

.menu-item-profile li:nth-child(2),
.menu-item-profile li:nth-child(3) {
  cursor: inherit;
}
.menu-item-profile li:nth-child(4) {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 220px;
  display: block;
}
.profile-dropdown {
  width: 220px;
  left: 0px !important;
}
@media screen and (max-width: 1366px) {
  .profile-dropdown {
    width: 220px;
    left: 0px !important;
  }
}
